import axios from "axios";

export interface Token {
  uuid: string;
  token: string;
  for: string;
  refresh_token: string;
  valid_until: string;
  valid_until_date?: Date;
  refresh_until: string;
  refresh_until_date?: Date;
}

export class TokenObject {
  private token: Token | null = null;

  public constructor() {
    const tokenString = sessionStorage.getItem('token');

    if (tokenString) {
      const tokenData: Token = JSON.parse(tokenString);

      this.token = Object.assign({}, tokenData);
      this.token.valid_until_date = new Date(Date.parse(tokenData.valid_until));
      this.token.refresh_until_date = new Date(Date.parse(tokenData.refresh_until));
    }
  }

  public getToken(): string | null {
    return this.token?.token || null;
  }

  public canRefresh(): boolean {
    if (!this.token) {
      return false;
    }

    return (this.token.refresh_until_date?.getTime() || 0) > new Date().getTime();
  }

  public isValid(): boolean {
    if (!this.token) {
      return false;
    }

    return (this.token.valid_until_date?.getTime() || 0) > new Date().getTime();
  }

  public for(): string | null {
    return this.token?.for || null;
  }

  public async refresh(): Promise<TokenObject> {
    const response = await axios.post('/auth/refresh', {
      refresh_token: this.token?.refresh_token
    });
    const tokenData: Token = response.data;

    tokenData.valid_until_date = new Date(Date.parse(response.data.valid_until));
    tokenData.refresh_until_date = new Date(Date.parse(response.data.refresh_until));

    this.token = tokenData;

    sessionStorage.setItem('token', JSON.stringify(this.token));

    return this;
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 10000,
  headers: {'X-Client': 'AnthroNRW-Storefront'}
});

instance.interceptors.request.use(config => {
  const tokenObject = new TokenObject();

  if (tokenObject.isValid()) {
    config.headers.Authorization = `Bearer ${tokenObject.getToken()}`;
  }

  return config;
}, error => Promise.reject(error));


export default instance;
