import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import http from "@/http";

import './index.css'
import {createPinia} from "pinia";

const pinia = createPinia();
const app = createApp(App);

app.use(router)
  .use(pinia)
  .provide('version', process.env.VUE_APP_VERSION)
  .provide('http', http)
  .mount('#app')
