<template>
  <div class="flex items-center justify-center w-full">
    <form class="bg-stone-900 p-8 rounded-lg shadow-lg w-full mx-16 md:max-w-3xl" @submit.prevent="login">
      <h1 class="text-3xl text-neutral-100 mb-4">POS-Login</h1>
      <div class="mb-4">
        <label for="email" class="block text-neutral-200 text-sm mb-2">Karte</label>
        <select id="email" class="w-full p-2 rounded-lg bg-stone-800 text-neutral-100" v-model="menu">
          <option v-for="menuOption in menus" :key="menuOption.ulid" :value="menuOption.ulid">{{
              menuOption.title
            }}
          </option>
        </select>
      </div>
      <div class="mb-4">
        <label for="password" class="block text-neutral-200 text-sm mb-2">Passwort</label>
        <input type="password" id="password" class="w-full p-2 rounded-lg bg-stone-800 text-neutral-100"
               autofocus
               :ref="pinCodeElement"
               v-model="pinCode"/>
      </div>
      <button type="submit"
              class="w-full bg-neutral-500 text-neutral-100 p-2 rounded-lg disabled:cursor-not-allowed disabled:text-neutral-400"
              :disabled="!canLogin">Login
      </button>
    </form>
  </div>
</template>

<script setup lang="ts">
import {computed, inject, onMounted, reactive, ref} from "vue";
import {Axios, AxiosResponse} from "axios";
import {Token, TokenObject} from "@/http";
import {useRouter} from "vue-router";

interface Menu {
  ulid: string;
  title: string;
}
const router = useRouter();

const pinCode = ref('');
const menu = ref('');
const menus: Menu[] = reactive([]);
const error = ref(null);
const pinCodeElement = ref<HTMLInputElement | null>(null);

const canLogin = computed(() => {
  return pinCode.value.length > 0 && menu.value.length > 0;
});

const http: Axios = inject('http') as Axios;

const login = () => {
  http.post('/menus/' + menu.value + '/authenticate', {pincode: pinCode.value})
    .then((response: AxiosResponse<Token>) => {
      sessionStorage.setItem('token', JSON.stringify(response.data));
    })
    .then(() => {
      router.push({name: 'store', params: {store: menu.value}});
    })
    .catch(e => {
      pinCode.value = '';
      pinCodeElement.value?.focus();
      error.value = e.message;
      console.error(e);
    });
};

onMounted(async () => {
  const tokenObject = new TokenObject();

  if (tokenObject.isValid()) {
    return router.replace({name: 'store', params: {store: tokenObject.for()}});
  }

  http.get('/menus')
    .then(response => {
      menus.push(...response.data.data);
      if (menus.length > 0) {
        menu.value = menus[0].ulid;
      }
    })
    .catch(error => {
      console.error(error);
    });

});

</script>
