<template>
  <div class="flex flex-col min-h-screen">
    <main class="flex flex-grow flex-1 bg-stone-500">
      <router-view/>
    </main>
    <footer class="bg-stone-900 text-neutral-200 p-2 text-sm space-x-16 flex items-center" id="footer">
      <span>Version: {{ version }}</span>
      <a class="cursor-pointer" href="#" @click.prevent="requestFullscreen">Vollbild</a>
      <a class="cursor-pointer" href="#" @click.prevent="logout" v-if="canLogout">Abmelden</a>
    </footer>
  </div>
  <div id="modal-root"/>
</template>
<script setup lang="ts">
import {computed, inject} from "vue";
import {useRoute, useRouter} from "vue-router";

const version = inject('version')

const router = useRouter();
const route = useRoute();

const canLogout = computed(() => {
  return route.meta?.requiresAuth;
});

const logout = () => {
  sessionStorage.removeItem('token');
  router.push({name: 'login'});
}

const requestFullscreen = () => {
  document.documentElement.requestFullscreen();
}
</script>
