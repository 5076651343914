import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import LoginView from '../views/LoginView.vue'
import {TokenObject} from "@/http";

declare module 'vue-router' {
  interface RouteMeta {
    requiresAuth?: boolean
  }
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: LoginView,
    meta: {}
  },
  {
    path: '/store/:store',
    name: 'store',
    meta: {requiresAuth: true},
    beforeEnter: (to, from, next) => {
        const tokenObject = new TokenObject();

        if(tokenObject.isValid()) {
          next();
        } else {
          sessionStorage.removeItem('token');
          next({name: 'login'});
        }
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "store" */ '../views/StoreView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
